<template>
  <div style="width: 94%;">
    <el-divider content-position="left">{{ title }}</el-divider>
  </div>
</template>

<script>
export default {
  name: 'HcYmhfTemplateDivider',
  props: {
    title: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
::v-deep .el-divider__text.is-left {
  font-family: PingFang SC;
  left: 0;
  font-size: 14px;
  line-height: 0.3rem;
  letter-spacing: 0px;
  color: #4E5969;
}
</style>
