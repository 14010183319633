<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="isView" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols">编辑</el-button>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="工作问题清单"></templateDivider>
    </div>

    <div class="box_content">
      <el-form label-position="top" label-width="80px" size="small" ref="form" :rules="rules" :model="form">
        <el-row :gutter="96">
          <el-col :span="6">
            <el-form-item class="budgetYear" label="新增年度" prop="year">
              <budgetYear ref="budgetYear" :isView="isView" @refreshYear="refreshYear" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="96" style="margin-top:16px;margin-bottom:16px;">
          <el-col :span="12">
            <el-form-item label="行政区域" prop="countyId">
              <el-cascader  :append-to-body="false"  
                :disabled="isView"
                v-model="form.countyId"
                size="mini"
                style="width: 100%;"
                :options="levalRegion"
                :props="treeProps"
                @change="handleChange($event)"
                :show-all-levels="false"
                placeholder="请选择"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="96" style="margin-top:16px;margin-bottom:16px;">
          <el-col :span="12">
            <el-form-item label="标题" prop="title">
              <el-input :disabled="isView" v-model="form.title" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="96" style="margin-top:16px;margin-bottom:16px;">
          <el-col :span="12">
            <el-form-item label="问题类型" prop="category">
              <el-input :disabled="isView" v-model="form.category" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="96" style="margin-top:16px;margin-bottom:16px;">
          <el-col :span="18">
            <el-form-item label="描述" prop="description">
              <el-input
                type="textarea"
                :disabled="isView"
                placeholder="输入问题正文，列出问题描述"
                style="width: 100%;resize: none;"
                rows="6"
                show-word-limit
                v-model="form.description"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="96" style="margin-top:16px;margin-bottom:16px;">
          <el-col :span="18">
            <el-form-item label="情况说明" prop="situationDescription">
              <el-input
                type="textarea"
                :disabled="isView"
                placeholder="请输入"
                style="width: 100%;resize: none;"
                rows="3"
                show-word-limit
                v-model="form.situationDescription"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="96" style="margin-top:16px;margin-bottom:16px;">
          <el-col :span="6">
            <el-form-item label="是否整改" prop="isRectified">
              <el-select :disabled="isView" size="mini" v-model="form.isRectified" placeholder="请选择">
                <el-option v-for="item in isRectifiedArr" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="整改时间" prop="rectifiedDate">
              <el-date-picker
                v-model="form.rectifiedDate"
                :disabled="isView"
                type="date"
                placeholder="请选择"
                format="yyyy-MM-DD"
                value-format="yyyy-MM-DD HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import templateSmallTilte from '@/components/templateView/templateSmallTilte.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _QuestionAdd,
  _QuestionInfo,
  _QuestionEdit
} from '@/api/modular/postImmigrationService/workingCondition/listOfQuestions.js'

export default {
  data() {
    return {
      type: '',
      loading: false,
      row: {},
      title: '',
      input: '',
      listData: [],
      options: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      form: {},
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }],
        countyId: [{ required: true, message: '请选择行政区域', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      isView: false,
      isRectifiedArr: [
        {
          label: '是',
          value: true
        },
        {
          label: '否',
          value: false
        }
      ]
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear,
    templateSmallTilte
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value) {
      let obj = this.getPids(value)
      this.form.countyId = obj.id
      this.form.county = obj.name
      this.form.countyPids = obj.pids
      this.form.orderNo = obj.orderNo
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.isView = true
      this.title = this.isView ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.isView = false
      this.title = '编辑'
    },
    btnClose() {
      this.$emit('handleBack')
    },
    refreshTable() {
      this.getBasicInfo()
    },

    submitForm() {
      // let params = {
      //   ...this.form,
      //   rectifiedDate: '',
      //   year: this.searchObj.year //年份
      // }

      let {
        countyId,
        county,
        countyPids,
        orderNo,
        title,
        category,
        description,
        isRectified,
        rectifiedDate,
        situationDescription
      } = this.form

      let params = {
        year: this.searchObj.year,
        countyId,
        county,
        countyPids,
        orderNo,
        title,
        category,
        description,
        isRectified,
        rectifiedDate,
        situationDescription
      }

      switch (this.type) {
        case 'add':
          _QuestionAdd([{ ...params }]).then(res => {
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.btnClose()
            } else {
              this.$message.error(res.message)
            }
          })
          break
        case 'edit':
          params.id = this.form.id
          _QuestionEdit(params).then(res => {
            if (res.code == 200) {
              this.type = 'view'
              this.$message.success('修改成功')
              this.isView = true
              this.getBasicInfo()
            } else {
              this.$message.error(res.message)
            }
          })
          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _QuestionInfo(params).then(res => {
        if (res.code == 200) {
          this.form = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
</style>
